import React from "react";
import {
  AppBar,
  Button,
  Link,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { ReactComponent as CallUsIcon } from "../../assets/icons/call_us.svg";
import { ReactComponent as WebsiteIcon } from "../../assets/icons/website.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/info.svg";
import { MobileDrawer } from "./MobileDrawer";

interface IPracticeDetails {
  phone?: string;
  name?: string;
  email?: string;
  website?: string;
}

export const formatPhone = (phone: string) => {
  if (!phone) return "";
  const parts = phone.replace(/\D/g, "").match(/(\d{3})(\d{3})(\d{4})/);
  return parts ? `(${parts[1]}) ${parts[2]}-${parts[3]}` : phone;
};
export const Header: React.FC<IPracticeDetails> = ({
  phone,
  website,
  email,
  name,
}) => {
  const formattedPhone = formatPhone(phone);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleInfoClick = () => {
    setIsDrawerOpen(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpen(false);
  };

  return (
    <>
      <MobileDrawer
        phone={phone}
        email={email}
        websiteUrl={website}
        isOpen={isDrawerOpen}
        closeDrawer={closeDrawer}
      />
      <AppBar
        component="nav"
        sx={{ backgroundColor: "#fff", borderBottom: "none" }}
        elevation={0}
      >
        <Toolbar sx={{ justifyContent: "space-between" }}>
          {name && <Typography variant="h6">{name}</Typography>}

          <Stack
            direction="row"
            sx={{
              display: { xs: "none", sm: "flex" }, // Show on 'xs' and 'sm', hide on 'md' and up
            }}
          >
            {phone && (
              <Stack direction="row" alignItems="center">
                <CallUsIcon />
                <Link
                  href={`tel:${phone}`}
                  color="text.primary"
                  underline="none"
                  pl={1}
                >
                  <Typography variant="h6" fontSize="0.85rem">
                    {formattedPhone}
                  </Typography>
                </Link>
              </Stack>
            )}

            {email && (
              <Stack direction="row" pl={3} alignItems="center">
                <EmailIcon />
                <Link
                  href={`mailto:${email}`}
                  color="text.primary"
                  underline="none"
                  pl={1}
                >
                  <Typography variant="h6" fontSize="0.85rem">
                    Email Us
                  </Typography>
                </Link>
              </Stack>
            )}

            {website && (
              <Stack direction="row" pl={3} alignItems="center">
                <WebsiteIcon />
                <Link
                  href={
                    website.startsWith("http") ? website : `https://${website}`
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  color="text.primary"
                  underline="none"
                  pl={1}
                >
                  <Typography variant="h6" fontSize="0.85rem">
                    Visit Us
                  </Typography>
                </Link>
              </Stack>
            )}
          </Stack>
          <Stack
            direction="row"
            sx={{
              display: {
                sm: "none",
              },
            }}
          >
            <Button onClick={handleInfoClick}>
              <InfoIcon />
            </Button>
          </Stack>
        </Toolbar>
      </AppBar>
    </>
  );
};
